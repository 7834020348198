import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
});

class Chat extends React.Component {
  state = {
    selectedIndex: 1
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index });
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <Title title="Chat" />
        <CardContent>
          <Grid container>
            <Grid item xs={3}>
              <List component="nav">
                <ListItem
                  button
                  selected={this.state.selectedIndex === 2}
                  onClick={event => this.handleListItemClick(event, 2)}
                >
                  <ListItemText primary="Trash" />
                </ListItem>
                <ListItem
                  button
                  selected={this.state.selectedIndex === 3}
                  onClick={event => this.handleListItemClick(event, 3)}
                >
                  <ListItemText primary="Spam" />
                </ListItem>
              </List>
            </Grid>
            <Grid item>b</Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

Chat.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Chat);
