import React, { createElement } from "react";
import { connect } from "react-redux";
import { MenuItemLink, getResources, Responsive } from "react-admin";
import { withRouter } from "react-router-dom";

const doesResourceBeginWith = (resource, text) =>
  !!resource.name.match(new RegExp(`^${text}`));

const showMenu = (resource, onMenuClick) => (
  <MenuItemLink
    key={resource.name}
    to={`/${resource.name}`}
    primaryText={(resource.options && resource.options.label) || resource.name}
    leftIcon={createElement(resource.icon)}
    onClick={onMenuClick}
  />
);

const MyMenu = ({ resources, onMenuClick, logout }) => {
  const menuGroups = {
    General: [],
    Advanced: [],
    ESAT: [],
    Incubator: []
  };

  resources.forEach(resource => {
    if (doesResourceBeginWith(resource, "ventures")) {
      menuGroups.Advanced.push(resource);
    } else if (doesResourceBeginWith(resource, "esat")) {
      menuGroups.ESAT.push(resource);
    } else if (doesResourceBeginWith(resource, "applications")) {
      menuGroups.Incubator.push(resource);
    } else {
      menuGroups.General.push(resource);
    }
  });

  return (
    <div>
      {Object.keys(menuGroups).map(groupName => (
        <React.Fragment key={groupName}>
          <div style={{ marginLeft: 10 }}>{groupName}</div>
          {menuGroups[groupName].map(resource =>
            showMenu(resource, onMenuClick)
          )}
        </React.Fragment>
      ))}
      <Responsive
        small={logout}
        medium={null} // Pass null to render nothing on larger devices
      />
    </div>
  );
};

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(MyMenu));
