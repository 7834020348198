import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  Filter,
  Responsive,
  SelectInput
} from "react-admin";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import LaunchIcon from "@material-ui/icons/Launch";
import { BarChartField } from "../components/BarChartField";
import CustomButton from "../components/CustomButton";
import LinkAnyFieldButton from "../components/LinkAnyFieldButton";

export default class MyButton extends React.Component {
  render() {
    return <Button>{this.props.icon}test</Button>;
  }
}

export const ChatList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<ChatFilter />}
        title="List of Recent Chats"
        sort={{ field: "muiCreatedDate", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="muiSource" label="Source" />
          <TextField source="faId" label="faId" />
          <TextField source="muiUserFirstName" label="Written By" />
          <TextField source="muiUserName" label="Email" />
          <TextField source="muiThread" label="Thread" />
          <TextField source="muiValue" label="Message" />
          <DateField source="muiCreatedDate" label="Created" />
          <LinkAnyFieldButton linkType="detail">
            <CustomButton icon={<LaunchIcon />} />
          </LinkAnyFieldButton>
          <LinkAnyFieldButton linkType="search">
            <CustomButton icon={<SearchIcon />} />
          </LinkAnyFieldButton>
        </Datagrid>
      </List>
    }
  />
);

const ChatResultStatuses = [
  { id: "InProgress", name: "In Prgress" },
  { id: "ReviewRequested", name: "Review Requested" },
  { id: "Completed", name: "Completed" }
];

export const ChatEdit = props => (
  <Edit title={<ChatTitle />} {...props}>
    <SimpleForm>
      <BarChartField source="srAssessments" label="Assessments" fullWidth />
      <DisabledInput source="id" />
      <DateTimeInput source="srCreatedDate" disabled={true} label="Created" />
      <DateTimeInput
        source="srLastUpdatedDate"
        disabled={true}
        label="Updated"
      />
      <SelectInput
        source="srStatus"
        choices={ChatResultStatuses}
        disabled={true}
      />

      <TextInput source="inId" label="Template" disabled={true} fullWidth />

      <TextInput source="srCompanyName" label="Company Name" fullWidth />
      <TextInput source="srContactName" label="Contact Name" fullWidth />
      <TextInput source="srContactEmail" label="Contact Email" fullWidth />
      <TextInput source="srContactPhone" label="Contact Phone" fullWidth />
      <TextInput source="srBizDesc" label="Biz Desc" fullWidth />
      <TextInput source="srLocation" label="Location" fullWidth />
    </SimpleForm>
  </Edit>
);

const ChatTitle = ({ record }) => {
  return <span>Chat {record ? `"${record.srContactName}"` : ""}</span>;
};

const ChatFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
