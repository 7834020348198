import axios from 'axios';

const REQUEST_TIMEOUT = 10000;

// create an axios instance
const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: REQUEST_TIMEOUT
});

// request interceptor
service.interceptors.request.use(config => {
    if (config.method === 'post') {
        config.headers['Content-type'] = 'application/json';
    }
    return config;
}, error => {
    console.log(error);
    Promise.reject(error);
})

export default service;
