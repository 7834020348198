// in src/Dashboard.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { TITLE } from './util/constant';

export default () => (
  <Card>
    <CardHeader title={TITLE} />
    <CardContent>
      Please sign out if you are not authorized to read this page. Please make
      sure that your access will be recorded and monitored.
    </CardContent>
  </Card>
);
