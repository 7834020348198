import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  FileInput,
  DateTimeInput,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  BooleanInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  Filter,
  Responsive,
  SelectInput,
  LongTextInput,
  ReferenceInput,
  FileField,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  DeleteButton
} from 'react-admin';
import { withStyles } from '@material-ui/core';

const UrlType = [
  { id: 'article', name: 'Article' },
  { id: 'video', name: 'Video' }
];

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const TagsField = ({ record, source }) => {
  return (
    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
      {record[source].map(item => (
        <li key={item.name}>{item.name}</li>
      ))}
    </ul>
  )
}
TagsField.defaultProps = { addLabel: true };


const FilesField = ({ record, source }) => {
  return (
    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
      {record[source].map(item => (
        <li key={item.id}>{item.title}</li>
      ))}
    </ul>
  )
}
FilesField.defaultProps = { addLabel: true };

export const ResourceList = props => (
  <Responsive
    medium={
      <List {...props} filters={<ResourceFilter />} title="List of Resources" sort={{ field: 'reCreatedDate', order: 'DESC' }}>
        <Datagrid>
          <TextField source="reTitle" label="Resource Title" />
          <TextField source="rcName" label="Category" />
          <TagsField source="reAuthors" label="Authors" />
          <TagsField source="reKeywords" label="Keywords" />
          <BooleanField source="reIsActive" label="Active" />
          <FilesField source="reFiles" src="src" label="Files" />
          <DateField source="reCreatedDate" label="Created" />
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

export class ResourceEdit extends Component {
  render() {
    return (
      <Edit title={<ResourceTitle />} undoable={false} {...this.props}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <DisabledInput source="id" />
          <DateTimeInput source="reCreatedDate" disabled={true} label="Created" />
          <BooleanInput source="reIsActive" label="Is Active" />
          <ReferenceInput label="Category" source="rcId" reference="resource-category" perPage={100}>
            <SelectInput optionText="rcName" />
          </ReferenceInput>
          <TextInput source="reTitle" label="Title" />
          <ImageInput source="reImageUrls" label="Picture" accept="image/*" multiple={true}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <LongTextInput source="reContent" label="Summary" style={{ width: '100%' }} />
          <ArrayInput source="reAuthors" label="Authors" >
            <SimpleFormIterator>
              <TextInput source="name" label="Author" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="reKeywords" label="Keywords">
            <SimpleFormIterator>
              <TextInput source="name" label="Keyword" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="reUrls" label="Urls" >
            <SimpleFormIterator>
              <SelectInput source="type" choices={UrlType} />
              <TextInput source="title" label="Title" />
              <TextInput source="url" label="Url" />
            </SimpleFormIterator>
          </ArrayInput>
          <FileInput source="reFiles" label="File" accept="application/*" multiple={true}>
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Edit>
    );
  }
}

export const ResourceCreate = props => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <BooleanInput source="reIsActive" label="Is Active" defaultValue={true} />
      <ReferenceInput label="Category" source="rcId" reference="resource-category" perPage={100}>
        <SelectInput optionText="rcName" />
      </ReferenceInput>
      <TextInput source="reTitle" label="Title" style={{ width: '100%' }} />
      <ImageInput source="reImageUrls" label="Picture" accept="image/*" multiple={true}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <LongTextInput source="reContent" label="Summary" style={{ width: '100%' }} />
      <ArrayInput source="reAuthors" label="Authors" >
        <SimpleFormIterator>
          <TextInput source="name" label="Full Name" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="reKeywords" label="Keywords" >
        <SimpleFormIterator>
          <TextInput source="keyword" label="Keyword" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="reUrls" label="Urls" >
        <SimpleFormIterator>
          <SelectInput source="type" choices={UrlType} />
          <TextInput source="title" label="Title" />
          <TextInput source="url" label="Url" />
        </SimpleFormIterator>
      </ArrayInput>
      <FileInput source="reFiles" label="File" accept="application/*" multiple={true}>
        <FileField source="src" title="title" />
      </FileInput>
      {/*
      <ArrayInput source="inIds" label="Industries" >
        <SimpleFormIterator>
          <TextInput label="Industry" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="ptNames" label="Product Type" >
        <SimpleFormIterator>
          <TextInput label="Product Type" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="bmNames" label="Businesss Models" >
        <SimpleFormIterator>
          <TextInput label="Businesss Model" />
        </SimpleFormIterator>
      </ArrayInput>
      */}
    </SimpleForm>
  </Create >
);

const ResourceTitle = ({ record }) => {
  return <span>Resource {record ? `"${record.reTitle}"` : ''}</span>;
};

const ResourceFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Category" source="rcId" reference="resource-category" alwaysOn perPage={100}>
      <SelectInput optionText="rcName" />
    </ReferenceInput>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
