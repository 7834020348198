import * as firebase from "firebase/app";
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDYidp8-XSahcJ3_iuM1NDfu4gWuQzBOy8",
    authDomain: "startboxio.firebaseapp.com",
    databaseURL: "https://startboxio.firebaseio.com",
    projectId: "startboxio",
    storageBucket: "startboxio.appspot.com",
    messagingSenderId: "839503138381",
    appId: "1:839503138381:web:3dc0225707cd572d"
};
firebase.initializeApp(firebaseConfig);
const RootStorage = 'resources';
const storage = firebase.storage();
const rootRef = storage.ref().child(RootStorage);

// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const uploadToFirebase = p => new Promise((resolve, reject) => {
    const newRef = rootRef.child(`${p.rawFile.lastModified}_${p.title}`);
    newRef.put(p.rawFile).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {
            resolve({ file: p, url: downloadURL });
        });
    });
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => async (type, resource, params) => {
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'resource') {
        // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
        // if parameter `multiple` is false, then data.pictures is not an array, but single object
        if (params.data.reFiles && params.data.reFiles.length) {
            // only freshly dropped pictures are instance of File
            const formerPictures = params.data.reFiles.filter(p => !(p.rawFile instanceof File));
            const newPictures = params.data.reFiles.filter(p => {
                return p.rawFile instanceof File;
            });

            const promises = newPictures.map(uploadToFirebase);
            const results = await Promise.all(promises.map(p => p.catch(e => e)));
            const errors = results.filter(res => !!(res instanceof Error))
            if (errors.length) {
                throw new Error('Failed to upload');
            }

            results.map(item => {
                let reUrls;
                if (params.data.reUrls) {
                    reUrls = params.data.reUrls;
                } else {
                    params.data.reUrls = reUrls = [];
                }
                reUrls.push({
                    title: item.file.title,
                    type: 'article',
                    url: item.url
                });
                return item.file;
            });

            return requestHandler(type, resource, {
                ...params,
                data: {
                    ...params.data,
                    reFiles: [...formerPictures],
                },
            });
        }

        if (params.data.reImageUrls && params.data.reImageUrls.length) {
            // only freshly dropped pictures are instance of File
            const formerPictures = params.data.reImageUrls.filter(p => !(p.rawFile instanceof File));
            const newPictures = params.data.reImageUrls.filter(p => p.rawFile instanceof File);

            return Promise.all(newPictures.map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                    src: picture64,
                    title: `${newPictures[index].title}`,
                })))
                .then(transformedNewPictures => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        reImageUrls: [...transformedNewPictures, ...formerPictures],
                    },
                }));
        }
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;
