import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  Filter,
  Responsive,
  SelectInput,
  ReferenceInput
} from "react-admin";
import LaunchIcon from "@material-ui/icons/Launch";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import { BarChartField } from "../components/BarChartField";
import CustomButton from "../components/CustomButton";
import LinkAnyFieldButton from "../components/LinkAnyFieldButton";
export const VentureList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<VentureFilter />}
        title="List of Recent Ventures"
        sort={{ field: "faLastUpdatedDate", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="id" label="Id" />
          <TextField source="faCompanyName" label="Company Name" />
          <TextField source="faCreatedBy" label="Email" />
          <TextField source="faStatus" label="Status" />
          <DateField source="faCreatedDate" label="Create" />
          <DateField source="faLastUpdatedDate" label="Last Updated" />
          <LinkAnyFieldButton
            linkType="href"
            baseHref={`${process.env.REACT_APP_BASE_URL}/#/mydashboard/index`}
          >
            <CustomButton icon={<LaunchIcon />} />
          </LinkAnyFieldButton>
          <LinkAnyFieldButton
            linkType="href"
            baseHref={`${process.env.REACT_APP_BASE_URL}/admin/file/xls`}
          >
            <CustomButton icon={<FileDownloadIcon />} />
          </LinkAnyFieldButton>
          <LinkAnyFieldButton
            linkType="href"
            baseHref={`${process.env.REACT_APP_BASE_URL}/admin/file/pdf`}
          >
            <CustomButton icon={<FileDownloadIcon />} />
          </LinkAnyFieldButton>
        </Datagrid>
      </List>
    }
  />
);

const VentureResultStatuses = [
  { id: "InProgress", name: "In Prgress" },
  { id: "ReviewRequested", name: "Review Requested" },
  { id: "Completed", name: "Completed" }
];

export const VentureEdit = props => (
  <Edit title={<VentureTitle />} {...props}>
    <SimpleForm>
      <BarChartField source="srAssessments" label="Assessments" fullWidth />
      <DisabledInput source="id" />
      <DateTimeInput source="srCreatedDate" disabled={true} label="Created" />
      <DateTimeInput
        source="srLastUpdatedDate"
        disabled={true}
        label="Updated"
      />
      <SelectInput
        source="srStatus"
        choices={VentureResultStatuses}
        disabled={true}
      />

      <TextInput source="inId" label="Template" disabled={true} fullWidth />

      <TextInput source="srCompanyName" label="Company Name" fullWidth />
      <TextInput source="srContactName" label="Contact Name" fullWidth />
      <TextInput source="srContactEmail" label="Contact Email" fullWidth />
      <TextInput source="srContactPhone" label="Contact Phone" fullWidth />
      <TextInput source="srBizDesc" label="Biz Desc" fullWidth />
      <TextInput source="srLocation" label="Location" fullWidth />
    </SimpleForm>
  </Edit>
);

const VentureTitle = ({ record }) => {
  return <span>Venture {record ? `"${record.srContactName}"` : ""}</span>;
};

const VentureFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Group"
      source="ugId"
      reference="user-groups"
      alwaysOn
      perPage={100}
    >
      <SelectInput optionText="ugName" />
    </ReferenceInput>
    <TextInput label="Id" source="id" alwaysOn />
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
