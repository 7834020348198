import React from "react";
import { Button } from "react-admin";

export default class CustomButton extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return <Button onClick={this.handleClick}>{this.props.icon}</Button>;
  }
}
