import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import { loginByUsername } from "./api/user";

export default async (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const response = await loginByUsername(username, password);
    var token = response.headers.authorization;
    localStorage.setItem("username", username);
    localStorage.setItem("token", token);
    return Promise.resolve();
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status, body } = params;
    if (body) {
      if (body.exception === "io.jsonwebtoken.ExpiredJwtException") {
        localStorage.removeItem("username");
        localStorage.removeItem("token");
        return Promise.reject();
      }
    }
    if (status === 401 || status === 403) {
      localStorage.removeItem("username");
      localStorage.removeItem("token");
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem("username")
      ? Promise.resolve()
      : Promise.reject();
  }
  return Promise.reject("Unknown method");
};
