// in src/App.js
import React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import ApplicationIcon from "@material-ui/icons/Description";
import ResourceIcon from "@material-ui/icons/Folder";
import ResourceCategoryIcon from "@material-ui/icons/Folder";
import AppUserIcon from "@material-ui/icons/People";
import UserGroupIcon from "@material-ui/icons/RecentActors";
import SurveyTemplateIcon from "@material-ui/icons/Terrain";
import SurveyIcon from "@material-ui/icons/Note";
import ChatIcon from "@material-ui/icons/Chat";
import VentureIcon from "@material-ui/icons/Chat";
import { createMuiTheme } from "@material-ui/core/styles";

import { ApplicationList, ApplicationEdit } from "./views/applications";
import { ResourceList, ResourceCreate, ResourceEdit } from "./views/resources";
import {
  ResourceCategoryList,
  ResourceCategoryCreate,
  ResourceCategoryEdit
} from "./views/resourceCategories";
import { AppUserList, AppUserEdit } from "./views/appUsers";
import {
  UserGroupList,
  UserGroupEdit,
  UserGroupCreate
} from "./views/userGroups";
import { ChatList } from "./views/chats";
import { VentureList } from "./views/ventures";
import {
  SurveyTemplateList,
  SurveyTemplateCreate,
  SurveyTemplateEdit
} from "./views/surveyTemplates";
import { SurveyList, SurveyEdit } from "./views/surveys";

import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import { createBrowserHistory } from "history";
import { TITLE, API_BASE_URL } from "./util/constant";
import customRoutes from "./customRoutes";
import SimpleLayout from "./layout/SimpleLayout";
import addUploadFeature from "./addUploadFeature";

const MainColor = "#26a717";
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: MainColor
    }
  }
});

const history = createBrowserHistory();

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.user = {
    authenticated: !!token,
    token: token
  };
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(API_BASE_URL, httpClient);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
  <Admin
    appLayout={SimpleLayout}
    customRoutes={customRoutes}
    theme={theme}
    title={TITLE}
    history={history}
    dashboard={Dashboard}
    dataProvider={uploadCapableDataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="ventures"
      options={{ label: "Ventures" }}
      list={VentureList}
      icon={VentureIcon}
    />
    <Resource
      name="users"
      options={{ label: "Users" }}
      list={AppUserList}
      edit={AppUserEdit}
      icon={AppUserIcon}
    />
    <Resource
      name="user-groups"
      options={{ label: "User Groups" }}
      list={UserGroupList}
      edit={UserGroupEdit}
      create={UserGroupCreate}
      icon={UserGroupIcon}
    />
    <Resource
      name="applications"
      options={{ label: "Incubator Applications" }}
      list={ApplicationList}
      edit={ApplicationEdit}
      icon={ApplicationIcon}
    />
    <Resource
      name="resource-category"
      options={{ label: "Resource Categories" }}
      list={ResourceCategoryList}
      create={ResourceCategoryCreate}
      edit={ResourceCategoryEdit}
      icon={ResourceCategoryIcon}
    />
    <Resource
      name="resource"
      options={{ label: "Resources" }}
      list={ResourceList}
      create={ResourceCreate}
      edit={ResourceEdit}
      icon={ResourceIcon}
    />
    <Resource
      name="chats"
      options={{ label: "Chats" }}
      list={ChatList}
      icon={ChatIcon}
    />
    <Resource
      name="esat-survey-template"
      options={{ label: "ESAT Templates" }}
      list={SurveyTemplateList}
      edit={SurveyTemplateEdit}
      create={SurveyTemplateCreate}
      icon={SurveyTemplateIcon}
    />
    <Resource
      name="esat-survey"
      options={{ label: "ESAT Survey" }}
      list={SurveyList}
      edit={SurveyEdit}
      icon={SurveyIcon}
    />
  </Admin>
);
export default App;
