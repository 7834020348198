import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  EditButton,
  Edit,
  SimpleForm,
  DisabledInput,
  BooleanInput,
  TextInput,
  Filter,
  Responsive,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceInput,
  SelectInput
} from "react-admin";

export const AppUserList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<AppUserFilter />}
        title="List of AppUsers"
        sort={{ field: "userCreatedDate", order: "DESC" }}
      >
        <Datagrid>
          <ReferenceArrayField
            label="Groups"
            reference="user-groups"
            source="ugIds"
          >
            <SingleFieldList>
              <ChipField source="ugName" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="id" label="Email" />
          <TextField source="userFirstName" label="First Name" />
          <TextField source="userLastName" label="Last Name" />
          <TextField source="userIsActive" label="Active" />
          <DateField source="userCreatedDate" label="Created" />
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

export const AppUserEdit = props => (
  <Edit title={<AppUserTitle />} undoable={false} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DateTimeInput source="userCreatedDate" disabled={true} label="Created" />
      <TextField source="userNumLoginFailed" label="# of Login Failed" />
      <BooleanInput source="userIsActive" label="Active" />
      <ReferenceArrayInput
        source="ugIds"
        label="User Groups"
        reference="user-groups"
      >
        <SelectArrayInput optionText="ugName" />
      </ReferenceArrayInput>
      <TextInput source="userFirstName" label="First Name" />
      <TextInput source="userLastName" label="Last Name" />
      <DateTimeInput
        source="userPasswdKeyGenDate"
        disabled={true}
        label="Password Reset Date"
      />
      <BooleanField source="userIsEmailAuth" label="Is Email Auth" />
    </SimpleForm>
  </Edit>
);

const AppUserTitle = ({ record }) => {
  return <span>AppUser {record ? `"${record.iaVentureTitle}"` : ""}</span>;
};

const AppUserFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Group"
      source="ugId"
      reference="user-groups"
      alwaysOn
      perPage={100}
    >
      <SelectInput optionText="ugName" />
    </ReferenceInput>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
