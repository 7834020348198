import React, { cloneElement, Component, Children } from "react";
import PropTypes from "prop-types";
import { withDefaultValue } from "ra-core";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

export class BarChartField extends Component {
  renderFieldArray = fieldProps => {
    const { children, record, resource, source } = this.props;
    console.log(source);
    const elem = cloneElement(Children.only(children), {
      ...fieldProps,
      record,
      resource,
      source
    });
    return elem;
  };

  render() {
    const { myAssessment, srAssessments } = this.props.record;

    const assesments = [];
    assesments.push(myAssessment);
    srAssessments.forEach(item => {
      assesments.push(item);
    });

    const data = [];
    const choices = myAssessment.saMyChoices;
    for (let i = 0; i < choices.length; i++) {
      data.push({
        name: `Q${i + 1}`
      });
      assesments.forEach(assessment => {
        data[i][assessment.userName] = assessment.saMyChoices[i];
      });
    }

    const renderLegend = props => {
      const { payload } = props;
      const round = val => {
        return Math.round(val * 100) / 100;
      };
      return (
        <ul
          className="recharts-default-legend"
          style={{ padding: "0px", margin: "0px", textAlign: "center" }}
        >
          {payload.map((entry, index) => (
            <li
              key={`item-${index}`}
              className="recharts-legend-item legend-item-0"
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: "inline-block",
                  verticalalign: "middle",
                  marginRight: "4px"
                }}
              >
                <path
                  stroke="none"
                  fill={entry.payload.fill}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                />
              </svg>
              <span className="recharts-legend-item-text">
                {entry.value} ({round(assesments[index].saScore)})
              </span>
            </li>
          ))}
        </ul>
      );
    };
    return (
      <div style={{ width: "100%", height: 300, marginTop: "50px" }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend content={renderLegend} />
            {assesments.map((assessment, idx) => (
              <Bar
                key={assessment.userName}
                dataKey={assessment.userName}
                fill={COLORS[idx]}
              />
            ))}
            }
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042"
];

BarChartField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  record: PropTypes.object,
  options: PropTypes.object,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ])
};

BarChartField.defaultProps = {
  options: {},
  fullWidth: true
};

export default withDefaultValue(BarChartField);
