import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  Filter,
  Responsive,
  SelectInput,
  LongTextInput,
  NumberInput
} from "react-admin";

export const SurveyTemplateList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<SurveyTemplateFilter />}
        title="List of ESAT Templates"
        sort={{ field: "stId", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="stName" label="Template Name" />
          <DateField source="stCreatedDate" label="Created" />
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

export const SurveyTemplateEdit = props => (
  <Edit title={<SurveyTemplateTitle />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DateTimeInput source="stCreatedDate" disabled={true} label="Created" />
      <TextInput source="stName" label="Template Name" fullWidth />
      <ArrayInput source="stQuestions" fullWidth>
        <SimpleFormIterator>
          <LongTextInput source="sqQuestion" fullWidth />
          <SelectInput
            source="sqType"
            choices={[
              { id: "Multiple", name: "Multiple" },
              { id: "Essay", name: "Essay" }
            ]}
          />
          <NumberInput source="sqWeighFactor" label="Weight Factor" />
          <ArrayInput source="sqChoices" fullWidth>
            <SimpleFormIterator>
              <TextInput source="qcDesc" label="Desc" fullWidth />
              <NumberInput source="qcPoint" label="Point" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const SurveyTemplateCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="stName"
        label="Template Name"
        style={{ width: "100%" }}
      />
    </SimpleForm>
  </Create>
);

const SurveyTemplateTitle = ({ record }) => {
  return <span>Survey Template {record ? `"${record.stName}"` : ""}</span>;
};

const SurveyTemplateFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
