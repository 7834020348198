import request from "../util/request";

export function loginByUsername(username, password) {
  const data = {
    id: username,
    userPasswd: password,
    userRoleIds: ["admin"]
  };
  return request({
    url: "/login",
    method: "post",
    data
  });
}
