import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  EditButton,
  Edit,
  SimpleForm,
  DisabledInput,
  SelectInput,
  LongTextInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  DateInput,
  Filter,
  SimpleList,
  Responsive
} from "react-admin";

export const ApplicationList = props => (
  <Responsive
    small={
      <List {...props}>
        <SimpleList
          primaryText={record => record.iaVentureTitle}
          secondaryText={record =>
            `${record.iaContactName} (${record.iaContactEmail})`
          }
          tertiaryText={record =>
            new Date(record.iaUpdatedDate).toLocaleDateString()
          }
        />
      </List>
    }
    medium={
      <List
        {...props}
        filters={<ApplicationFilter />}
        title="List of Applications"
        sort={{ field: "iaUpdatedDate", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="iaVentureTitle" label="Venture Title" />
          <TextField source="iaStatus" label="Status" />
          <TextField source="iaContactName" label="Contact Name" />
          <TextField source="iaOneLinePitch" label="One-line Pitch" />
          <DateField source="iaCreatedDate" label="Created" />
          <DateField source="iaUpdatedDate" label="Updated" />
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

const StatusOptions = [
  { id: "InProgress", name: "InProgress" },
  { id: "Applied", name: "Applied" },
  { id: "Approved", name: "Approved" },
  { id: "Rejected", name: "Rejected" }
];

export const ApplicationEdit = props => (
  <Edit title={<ApplicationTitle />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DisabledInput source="iaContactName" />
      <DisabledInput source="iaContactEmail" />
      <DateTimeInput source="iaCreatedDate" disabled={true} label="Created" />
      <DateTimeInput source="iaUpdatedDate" disabled={true} label="Updated" />
      <SelectInput source="iaStatus" label="Status" choices={StatusOptions} />
      <LongTextInput source="iaVentureTitle" label="Venture Title" />
      <LongTextInput source="iaOneLinePitch" label="One-line Pitch" />
      <LongTextInput source="iaProjectSummary" label="Project Summary" />
      <LongTextInput source="iaManagement" label="Management" />
      <LongTextInput source="iaEndUserProblem" label="End-user Problem" />
      <LongTextInput source="iaTargetMarket" label="Target Market" />
      <LongTextInput
        source="iaCustomerValidation"
        label="Customer Validation"
      />
      <LongTextInput source="iaTechValidation" label="Technology Validation" />
      <LongTextInput source="iaSalesStrategy" label="Sales Strategy" />
      <LongTextInput source="iaBusinessModel" label="Business Model" />
      <LongTextInput
        source="iaCompetitorSolution"
        label="Competitor Solution"
      />
      <LongTextInput
        source="iaCompetitiveAdvantage"
        label="Competitive Advantage"
      />
      <ArrayInput
        source="iaRiskAssessments"
        label="Risk Assessments"
        style={{ width: "100%" }}
      >
        <SimpleFormIterator>
          <LongTextInput source="irRiskFactor" label="Risk Factor" />
          <LongTextInput
            source="irMitigationStrategy"
            label="Mitigation Strategy"
          />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="iaFunds" label="Funds" style={{ width: "100%" }}>
        <SimpleFormIterator>
          <LongTextInput source="ifSourceOfFund" label="Source of Fund" />
          <LongTextInput source="ifActivity" label="Activity" />
          <NumberInput
            source="ifFundRequired"
            label="Funds Required ($)"
            step={1000}
          />
          <LongTextInput source="ifDeliverable" label="Deliverable" />
          <DateInput source="ifDeliveryDate" label="Delivery Date" />
        </SimpleFormIterator>
      </ArrayInput>
      <LongTextInput source="iaOtherInfo" label="Other Info" />
    </SimpleForm>
  </Edit>
);

const ApplicationTitle = ({ record }) => {
  return <span>Application {record ? `"${record.iaVentureTitle}"` : ""}</span>;
};

const ApplicationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
