import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  EditButton,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  Filter,
  Responsive,
  SelectInput
} from "react-admin";
import { BarChartField } from "../components/BarChartField";

import LaunchIcon from "@material-ui/icons/Launch";
import CustomButton from "../components/CustomButton";
import LinkAnyFieldButton from "../components/LinkAnyFieldButton";

export const SurveyList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<SurveyFilter />}
        title="List of ESAT Templates"
        sort={{ field: "srLastUpdatedDate", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="srStatus" label="Status" />
          <TextField source="srCompanyName" label="Company" />
          <TextField source="srContactName" label="Contact" />
          <TextField source="srContactEmail" label="Email" />
          <TextField source="inId" label="Template" />
          <DateField source="srCreatedDate" label="Created" />
          <DateField source="srLastUpdatedDate" label="Updated" />
          <LinkAnyFieldButton
            linkType="href"
            baseHref={`${process.env.REACT_APP_BASE_URL}/esat/assess/assess`}
          >
            <CustomButton icon={<LaunchIcon />} />
          </LinkAnyFieldButton>
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

const SurveyResultStatuses = [
  { id: "InProgress", name: "In Prgress" },
  { id: "ReviewRequested", name: "Review Requested" },
  { id: "Completed", name: "Completed" }
];

export const SurveyEdit = props => (
  <Edit title={<SurveyTitle />} {...props}>
    <SimpleForm>
      <BarChartField source="srAssessments" label="Assessments" fullWidth />
      <DisabledInput source="id" />
      <DateTimeInput source="srCreatedDate" disabled={true} label="Created" />
      <DateTimeInput
        source="srLastUpdatedDate"
        disabled={true}
        label="Updated"
      />
      <SelectInput
        source="srStatus"
        choices={SurveyResultStatuses}
        disabled={true}
      />

      <TextInput source="inId" label="Template" disabled={true} fullWidth />

      <TextInput source="srCompanyName" label="Company Name" fullWidth />
      <TextInput source="srContactName" label="Contact Name" fullWidth />
      <TextInput source="srContactEmail" label="Contact Email" fullWidth />
      <TextInput source="srContactPhone" label="Contact Phone" fullWidth />
      <TextInput source="srBizDesc" label="Biz Desc" fullWidth />
      <TextInput source="srLocation" label="Location" fullWidth />
    </SimpleForm>
  </Edit>
);

const SurveyTitle = ({ record }) => {
  return <span>Survey {record ? `"${record.srContactName}"` : ""}</span>;
};

const SurveyFilter = props => (
  <Filter {...props}>
    <TextInput label="Id" source="id" alwaysOn />
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
