import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  Filter,
  Responsive
} from "react-admin";

export const UserGroupList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<UserGroupFilter />}
        title="List of User Groups"
        sort={{ field: "ugCreatedDate", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="ugName" label="Group Name" />
          <DateField source="ugCreatedDate" label="Created" />
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

export const UserGroupEdit = props => (
  <Edit title={<UserGroupTitle />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DateTimeInput source="ugCreatedDate" disabled={true} label="Created" />
      <TextInput source="ugName" label="Category Name" fullWidth />
    </SimpleForm>
  </Edit>
);

export const UserGroupCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="ugName" label="Group Name" fullWidth />
    </SimpleForm>
  </Create>
);

const UserGroupTitle = ({ record }) => {
  return <span>UserGroup {record ? `"${record.iaVentureTitle}"` : ""}</span>;
};

const UserGroupFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
