import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateTimeInput,
  EditButton,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  Filter,
  Responsive,
  NumberInput
} from "react-admin";

export const ResourceCategoryList = props => (
  <Responsive
    medium={
      <List
        {...props}
        filters={<ResourceCategoryFilter />}
        title="List of Resource Categories"
        sort={{ field: "rcOrder", order: "ASC" }}
      >
        <Datagrid>
          <TextField source="rcName" label="Category" />
          <TextField source="rcOrder" label="Order" />
          <DateField source="rcCreatedDate" label="Created" />
          <EditButton />
        </Datagrid>
      </List>
    }
  />
);

export const ResourceCategoryEdit = props => (
  <Edit title={<ResourceCategoryTitle />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DateTimeInput source="rcCreatedDate" disabled={true} label="Created" />
      <TextInput
        source="rcName"
        label="Category Name"
        style={{ width: "100%" }}
      />
      <NumberInput source="rcOrder" label="Order" defaultValue="100" />
    </SimpleForm>
  </Edit>
);

export const ResourceCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="rcName"
        label="Category Name"
        style={{ width: "100%" }}
      />
      <NumberInput source="rcOrder" label="Order" defaultValue="100" />
    </SimpleForm>
  </Create>
);

const ResourceCategoryTitle = ({ record }) => {
  return (
    <span>ResourceCategory {record ? `"${record.iaVentureTitle}"` : ""}</span>
  );
};

const ResourceCategoryFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);
